import { Children, Fragment, cloneElement } from "react";
import Tooltip from "@mui/material/Tooltip";

import { useAtomValue } from "jotai";

import { useState } from "react";
import { useHttpPost } from "./hooks";

import { getShortDescription } from "./storydat";

import { projectPickerState } from "./JotaiAtoms";

import * as u from "./utility";
import { Box, Typography } from "@mui/material";

export function StoryPopup({
  storyMoniker,
  children,
  enterDelay,
  previewComponent,
  placement,
}) {
  const [story, setStory] = useState(null);
  const [hover, setHover] = useState(false);
  const project = useAtomValue(projectPickerState);
  const httpPost = useHttpPost();

  const handleMouseEnter = () => {
    const request = async () => {
      try {
        const query =
          storyMoniker[0] == "[" ? storyMoniker.slice(1, -1) : storyMoniker;
        const req = { search: true, query: query, project: project };
        const resp = await httpPost("stories", req);
        const found = resp.data.search;
        if (found) {
          setStory(found[0]);
          return;
        }
      } catch (e) {}
      setStory({ error: true });
    };

    setHover(true);
    if (!story) {
      setTimeout(() => {
        setHover((prev) => {
          if (prev) {
            request();
          }
          return prev;
        });
      }, 300);
    }
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const renderPreview = () => {
    return Children.map(previewComponent, (child) => {
      return cloneElement(child, {
        story: story,
      });
    });
  };

  if (story) {
    if (story.error) {
      return <>{children}</>;
    } else {
      return (
        <Tooltip
          enterDelay={enterDelay || 100}
          enterNextDelay={enterDelay || 100}
          placement={placement}
          title={
            <Fragment>
              {previewComponent && <>{renderPreview()}</>}
              {!previewComponent && (
                <Typography fontSize={11}>
                  {getShortDescription(story)}
                </Typography>
              )}
            </Fragment>
          }
        >
          {children}
        </Tooltip>
      );
    }
  } else {
    return (
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        component={"span"}
      >
        {children}
      </Box>
    );
  }
}
