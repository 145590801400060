import { Fragment, useState } from "react";

import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Grid from "@mui/material/Grid";

import { captionsState, projectPickerState } from "./JotaiAtoms";

import { rebuildMessageText } from "./storydat";

import { SendMessageBox, unfinishedMessageFromCache } from "./SendMessageBox";
import { AutocompleteButton } from "./AutocompleteButton";
import { AutocompleteStory } from "./AutocompleteStory";
import { Thread } from "./Thread";
import store from "store2";

import * as u from "./utility";
import log from "./logger";
import { Icon } from "@iconify/react";
import { useAtomValue } from "jotai";
import { useHttpPost } from "./hooks";
import { StoryPopup } from "./StoryCard";
import { MessagePreview } from "./MessagePreview";
import { TimelineChips, StoryChip } from "./TimeLineChips";

export function CommentSection({
  onReload,
  handleSort,
  sortingOrder,
  setMessages,
  onMessageSent,
  droppedFiles,
  handleUploadedFileRemoved,
  item,
  bottomInputPresent,
  children,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [message, setMessage] = useState(unfinishedMessageFromCache(item));
  const [password, setPassword] = useState("");
  const [previousMessage, setPreviousMessage] = useState("");
  const [focused, setFocused] = useState(false);

  const handleMessageSent = (msg) => {
    if (msg && msg.text) {
      setPreviousMessage(msg.text);
    }
    onMessageSent(msg);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <p />
        <Stack direction="row" gap={1} alignItems={"flex-start"}>
          <Stack direction="row" gap={1} sx={{ mt: 2 }}>
            <Box>
              <IconButton size="small" disabled={false} onClick={onReload}>
                <ReplayIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <Box>
              <Tooltip
                title={
                  sortingOrder
                    ? "Set newest at the top"
                    : "Set oldest at the top"
                }
              >
                <IconButton size="small" disabled={false} onClick={handleSort}>
                  <Icon
                    icon={sortingOrder ? "fa:sort-desc" : "fa:sort-asc"}
                    width={18}
                    height={18}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
          <SendMessageBox
            item={item}
            setMessages={setMessages}
            id="top"
            onSent={handleMessageSent}
            droppedFiles={droppedFiles}
            onFileRemoved={handleUploadedFileRemoved}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            message={message}
            setMessage={setMessage}
            password={password}
            setPassword={setPassword}
            previousMessage={previousMessage}
            onBlur={handleBlur}
            onFocus={handleFocus}
            focused={focused}
          />
        </Stack>
        <p />
      </Box>

      {children}

      {bottomInputPresent && (
        <Box>
          <p />
          <Stack direction="row" gap={1}>
            <SendMessageBox
              item={item}
              setMessages={setMessages}
              id="bottom"
              onSent={handleMessageSent}
              droppedFiles={droppedFiles}
              onFileRemoved={handleUploadedFileRemoved}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              message={message}
              setMessage={setMessage}
              password={password}
              setPassword={setPassword}
              previousMessage={previousMessage}
              onBlur={handleBlur}
              onFocus={handleFocus}
              focused={focused}
            />
          </Stack>
        </Box>
      )}
    </>
  );
}

export function StoryDetails({
  item,
  messages,
  setMessages,
  connected,
  setConnected,
  onNewMessage,
  onRequestReload,
  mode,
}) {
  const [sortingOrder, setSortingOrder] = useState(
    item ? store.get(`SortingOrder:${item.PK}:${item.SK}`, false) : false
  );
  const captions = useAtomValue(captionsState);
  const [dragActive, setDragActive] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState(null);
  const projectName = useAtomValue(projectPickerState);
  const httpPost = useHttpPost();

  const getData = async () => {
    try {
      const response = await httpPost("stories", {
        connected: true,
        story: item.SK,
      });
      setConnected(response.data.connected);
    } catch (err) {
      log.debug(err);
    } finally {
    }
  };

  const handleSort = () => {
    setSortingOrder(!sortingOrder);
    store(`SortingOrder:${item.PK}:${item.SK}`, !sortingOrder);
  };

  const getStoryCaption = (item) => {
    if (item.st_cap) {
      return item.st_cap;
    }

    const text = rebuildMessageText(item);
    if (text && text.length) {
      return text;
    }

    if ("SK4" in item) {
      return u.getStoryKey(item);
    }

    return item.SK;
  };

  const handleDelete = (other) => {
    const request = async (story) => {
      try {
        httpPost("label", {
          disconnect: {
            stories: [other.SK, item.SK],
          },
        });
      } catch (err) {}
    };
    request();

    let newValues = [];
    for (let v of connected) {
      if (v.SK !== other.SK) {
        newValues.push(v);
      }
    }
    setConnected(newValues);
  };

  const handleClick = (e, item) => {
    const url = u.getStoryUrl(item, projectName);

    if (e.metaKey) {
      e.stopPropagation();
      window.open(url, "_blank", "noreferrer");
    } else {
      window.location.href = url;
    }
  };

  const handleConnect = (value) => {
    const request = async (story) => {
      try {
        await httpPost("label", {
          connect: {
            stories: [story, item.SK],
          },
        });
        getData();
      } catch (err) {}
    };

    if (captions[projectName] && captions[projectName][value]) {
      request(captions[projectName][value]);
      return;
    }

    let story = value;
    try {
      const segments = new URL(value).pathname.split("/");
      const last = segments.pop() || segments.pop(); // Handle potential trailing slash
      story = last;
    } catch (e) {}

    if (story) {
      if (story.startsWith("STORY-")) {
        story = story.replace("STORY-", "");
      }
      request(story);
    }
  };

  const handleMessageSent = (msg) => {
    setDroppedFiles(null);
    if (onNewMessage) {
      onNewMessage(msg);
    }
  };

  const handleReload = () => {
    if (onRequestReload) {
      onRequestReload();
    }
  };

  const countVisibleMessage = (messages) => {
    let count = 0;
    if (messages) {
      for (let m of messages) {
        if (!m.st_hide) {
          count += 1;
        }
      }
    }
    return count;
  };

  const setMessagesOriginalOrder = (func) => {
    setMessages((prev) => {
      const sorted = u.sortMessages(func(prev), !sortingOrder);
      return sorted;
    });
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setDroppedFiles((prev) => {
      if (!prev) {
        return e.dataTransfer.files;
      } else {
        return [...prev, ...e.dataTransfer.files];
      }
    });
  };

  const handleUploadedFileRemoved = () => {
    setDroppedFiles(null);
  };

  return (
    <Stack
      direction="row"
      alignItems={"flex-start"}
      justifyContent="space-between"
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      sx={{
        background: dragActive ? "lightgrey" : "none",
        borderRadius: "4px",
      }}
    >
      <Box width="100%" sx={{ p: 0, m: 0, pb: 1 }}>
        <Divider sx={{ mt: 0.1, mb: 0.2 }} />
        <Stack gap={0.3} direction="row" alignItems={"center"} sx={{ m: 0 }}>
          <Box alignContent={"center"} sx={{ whiteSpace: "nowrap", ml: 5 }}>
            <Typography fontSize={9} color="silver" fontWeight={"bold"}>
              related stories:
            </Typography>
          </Box>
          <Grid container maxWidth="100%" spacing={0.1}>
            {connected &&
              connected.map((item) => (
                <Grid item key={item.SK}>
                  <Tooltip
                    key={item.SK}
                    title={
                      <Fragment>
                        <StoryFastPreview story={item} />
                      </Fragment>
                    }
                  >
                    <Chip
                      sx={{ maxWidth: "150px" }}
                      size="small"
                      label={getStoryCaption(item)}
                      onDelete={() => handleDelete(item)}
                      onClick={(e) => handleClick(e, item)}
                    />
                  </Tooltip>
                </Grid>
              ))}
            <Grid item key={u.getCurrentTimestamp()} sx={{ width: "20%" }}>
              <AutocompleteStory
                color="disabled"
                variant="standard"
                options={
                  captions[projectName]
                    ? Object.keys(captions[projectName])
                    : []
                }
                // options={[]}
                onSelected={handleConnect}
                sx={{ minWidth: "100" }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <StoryPopup
                      storyMoniker={option}
                      previewComponent={<StoryFastPreview />}
                      placement={"right"}
                    >
                      <Typography fontSize={12}>{option}</Typography>
                    </StoryPopup>
                  </Box>
                )}
              >
                <AddCircleIcon color={"disabled"} fontSize="inherit" />
              </AutocompleteStory>
            </Grid>
          </Grid>
        </Stack>

        <CommentSection
          onReload={handleReload}
          handleSort={handleSort}
          sortingOrder={sortingOrder}
          setMessages={setMessages}
          droppedFiles={droppedFiles}
          item={item}
          handleUploadedFileRemoved={handleUploadedFileRemoved}
          onMessageSent={handleMessageSent}
          bottomInputPresent={messages && countVisibleMessage(messages) > 15}
        >
          <>
            {messages && messages.length > 1 && (
              <Stack direction={"row"}>
                <Box sx={{ width: "50px" }}></Box>
                <Thread
                  messages={u.sortMessages(messages, sortingOrder)}
                  setMessages={setMessagesOriginalOrder}
                  allowReplay={false}
                  story={item}
                  background={dragActive ? "lightgray" : "white"}
                  overflow={false}
                />
              </Stack>
            )}
          </>
        </CommentSection>
      </Box>
    </Stack>
  );
}

export function StoryFastPreview({ story, fontSize, sx }) {
  return (
    <Stack direction="column" sx={{ pb: 0.4 }}>
      <Stack direction="row" sx={{ mb: 1, mt: 1 }}>
        {"SK4" in story && <StoryChip item={story} />}
        <TimelineChips item={story} />
      </Stack>
      {/* <StoryCell item={story} expand={false} hideControls={true} /> */}
      <Box>
        <MessagePreview
          item={story}
          fontSize={fontSize || 11}
          readOnly={true}
          sx={sx || { "&.MuiLink-root": { color: "lightskyblue" } }}
        />
      </Box>
    </Stack>
  );
}
