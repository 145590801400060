import { useEffect, useRef, useState, useMemo } from "react";
import * as u from "./utility";
import * as storydat from "./storydat";
import { useAtomValue } from "jotai";
import { projectPickerState, captionsState } from "./JotaiAtoms";
import {
  postHttpApi,
  getApiUrl,
  getHookUrl,
  uploadToS3,
  downloadFileFromS3Bucket,
} from "./Auth";
import { useLocation } from "react-router-dom";

export function useDoubleClick(onDoubleClick) {
  const ref = useRef(0);
  const handleClick = (e) => {
    const sinceLastClick = e.timeStamp - (ref.current ? ref.current : 0);

    ref.current = e.timeStamp;

    if (e.detail === 2 && sinceLastClick < 200) {
      onDoubleClick();
      e.stopPropagation();
    }
  };
  return handleClick;
}

export function useNewTabText() {
  const [textData, setTextData] = useState(null);

  const handleDoubleClick = useDoubleClick(() => {
    var wnd = window.open("", "_blank");
    wnd.document.open();
    wnd.document.write(`<pre>${u.escapeHtml(textData)}</pre>`);
    wnd.document.close();
  });

  return [textData, setTextData, handleDoubleClick];
}

export const useHttpPostAndProject = () => {
  const project = useAtomValue(projectPickerState);
  const stage = useStage();

  return [
    (path, request) =>
      postHttpApi(getApiUrl(stage), path, { project: project, ...request }),
    project,
  ];
};

export const useHttpPost = () => {
  return useHttpPostAndProject()[0];
};

export const useHttpApiPost = () => {
  const stage = useStage();

  return (path, request) => postHttpApi(getApiUrl(stage), path, request);
};

export const useProjectCache = (key, defaultValue = "") => {
  const project = useAtomValue(projectPickerState);
  const [cacheValue, setCacheValue] = useState(defaultValue);
  useEffect(() => {
    setCacheValue(u.readLocalStorage(`${key}:${project}`, ""));
  }, [project]);

  const updateCache = (value) => {
    u.writeLocalStorage(`${key}:${project}`, value);
  };

  return [cacheValue, setCacheValue, updateCache];
};

export const useSetMessageProps = () => {
  const httpPost = useHttpPost();

  const setProp = (item, key, value) => {
    if (!item) {
      return;
    }

    try {
      let request = {
        setmessageprops: {
          PK: item.PK,
          SK: item.SK,
        },
      };
      request.setmessageprops[key] = value;
      httpPost("label", request);
    } catch (err) {}
  };

  return setProp;
};

export const useExitToApp = () => {
  const httpPost = useHttpPost();

  const exitToApp = (item) => {
    if ("channel" in item || item.st_source === "slack") {
      // slack
      const request = async (myWindow) => {
        try {
          const story = item.SK;
          let params = { exittoapp: true, story: story };
          if (item.channel) {
            params.channel = item.channel;
          }
          const response = await httpPost("stories", params);
          myWindow.location.href = response.data.exittoapp.url;
        } catch (err) {
        } finally {
        }
      };
      if ("permalink" in item) {
        window.open(item.permalink, "_blank", "noreferrer");
      } else {
        const myWindow = window.open();
        request(myWindow);
      }
    } else {
      let url = storydat.getSourceUrl(item);

      if (url) {
        window.open(url, "_blank", "noreferrer");
      }
    }
  };
  return exitToApp;
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useStage() {
  let query = useQuery();
  return query.get("stage") || "prod2";
}

export function useHookUrl() {
  const stage = useStage();
  const project = useAtomValue(projectPickerState);
  return getHookUrl(stage) + project;
}

export function useUploadFile() {
  const stage = useStage();
  const project = useAtomValue(projectPickerState);

  return async (file) => {
    const userUploadBucket = `topstorie-user-uploads-${stage}`;
    return await uploadToS3(file, project, userUploadBucket);
  };
}

export function useDownloadFile() {
  const stage = useStage();
  return async (key) =>
    await downloadFileFromS3Bucket(key, `stumti-image-cache-eu-${stage}`);
}

export function useSilentDownload() {
  const downloadFileFromS3 = useDownloadFile();
  const project = useAtomValue(projectPickerState);

  return async (url, ext) => {
    try {
      const hash = await u.sha1(url);
      let key = `${project}/${hash}`;
      if (ext) {
        key = `${key}.${u.getExtFromUrl(url)}`;
      }
      await downloadFileFromS3(key);
    } catch {}
  };
}

export function useProjectCaptions() {
  const captions = useAtomValue(captionsState);
  const projectName = useAtomValue(projectPickerState);

  if (captions && captions[projectName]) {
    return captions[projectName];
  }

  return [];
}
